@import "helpers/breakpoints";
@import "helpers/extend";

@mixin font($fw, $fs, $lh: $lh-base, $ff: $regular-font) {
  font: $fw #{$fs}/#{$lh} $ff;
}

@mixin absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  margin: auto;
}

@mixin transition($prop: all, $duration: 0.25s, $easing: ease-out) {
  transition-duration: $duration;
  transition-property: $prop;
  transition-timing-function: $easing;
}

