@import "./reset/reset";
@import "./variables/variables";
@import "./mixins/mixins";

html {
  -webkit-text-size-adjust: none;
  scroll-behavior: smooth;
  font-family: $system-fonts-list;
}

* {
  box-sizing: border-box;
}

body {
  @extend %custom-scrollbar;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  color: $primary-text;
  font-weight: normal;
  line-height: 1;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include phones-landscape {
    padding: 0;
  }

  &.isMobMenuOpen {
    overflow: hidden;
  }
}

button {
  margin: 0;
  border: 0;
  background: transparent;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: 0;

  &::-moz-focus-inner {
    border: 0;
  }
}

a {
  @include transition(all, 0.25s, linear);
  color: $primary-text;
  outline: none;
  text-decoration: none;

  &:hover {
    @include transition(all, 0.25s, linear);
    color: $link-hover-color;
  }
}

h1 {
  @include font(600, 80px, 96px, $regular-font);

  @include tablets {
    @include font(600, 58px, 59px);
  }

  @include phones-landscape {
    @include font(600, 42px, 46px);
  }

  @include phones {
    @include font(600, 30px, 41px);
  }
}

h2 {
  @include font(600, 80px, 96px, $regular-font);

  @include tablets {
    @include font(600, 40px, 60px);
  }

  @include phones-landscape {
    @include font(600, 30px, 41px);
  }
}

h3 {
  @include font(600, 18px, 27px, $regular-font);
  text-transform: uppercase;
  color: $secondary-text;

  @include phones-landscape {
    @include font(700, 12px, 16px);
  }
}

h4 {
  @include font(300, 24px, 26px, $regular-font);

  @include phones-landscape {
    @include font(300, 14px, 21px);
  }
}

p {
  @include font(300, 18px, 27px, $regular-font);

  @include phones-landscape {
    @include font(300, 14px, 21px);
  }
}

ul,
div,
section,
textarea {
  @extend %custom-scrollbar;
}

// header
.header {
  @include font(400, 13px, 45px);
  position: relative;
  display: flex;
  height: 73px;
  background: $background;
  z-index: 3;
  margin-top: $gap4;

  @include phones-landscape {
    @include absolute(0, 0, auto, 0);
    position: fixed;
    height: 73px;
  }

  .logo {
    display: flex;
    width: 140px;
    height: 58px;

    @include phones-landscape {
      display: none;
    }
  }

  &-menu {
    display: flex;
    height: 100%;

    @include phones-landscape {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: $gap2;
    }

    &__wrap {
      width: 100%;
      height: 100%;
      display: flex;
      align-content: center;
      justify-content: space-between;
      margin: 0 50px;

      @include tablets {
        margin: 0 $gap2;
      }

      @include phones-landscape {
        display: none;
      }
    }

    &__item {
      @include font(300, 14px, 21px, $regular-font);
      display: inline-flex;
      align-items: center;
      margin-right: 37px;
      text-align: center;
      cursor: pointer;

      @include phones-landscape {
        padding: $gap1 0;
        margin: 0;
      }
    }
  }

  &__copyright {
    display: none;

    @include phones-landscape {
      display: flex;

      &::before {
        content: none;
      }
    }

    @include phones-landscape {
      @include font(400, 15px, 45px, $regular-font);
      margin: 0;
    }
  }

  &-mob {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 $gap2;

    @include phones-landscape {
      display: flex;
    }

    .logo-mob {
      display: flex;
      width: 80px;
      height: 33px;
    }

    &__btn {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      height: 12px;
      width: 18px;

      span {
        @include absolute();
        width: 100%;
        height: 2px;
        background: $black;

        &:first-of-type {
          transform: translateY(-5px);
        }

        &:last-of-type {
          transform: translateY(5px);
        }
      }
    }
  }

  &.isMobMenuOpen {
    position: fixed;
    bottom: 0;
    height: 100%;
    z-index: 20;
    background: $white;
    color: $black;
    flex-direction: column-reverse;

    .header-menu__wrap {
      display: flex;
      flex-direction: column;
      height: auto;
      flex-grow: 1;
    }

    .header-mob {
      display: flex;
      height: 73px;
      flex-shrink: 0;

      .logo_mob {
        width: 80px;
      }

      &__btn {
        span {
          @include transition();
          position: absolute;
          background: $black;

          &:first-of-type {
            transform: rotate(45deg);
          }

          &:nth-of-type(2n) {
            display: none;
          }

          &:last-of-type {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

// home
.home {
  background-image: url("../img/header.svg");
  background-repeat: no-repeat;
  background-position: center, center;
  height: calc(100vh - 73px);
  min-height: 750px;
  margin-top: 90px;
  width: 100%;
  z-index: 1;
  position: relative;

  @include tablets {
    background-image: url("../img/header-mob.svg");
    width: auto;
    align-items: center;
    background-size: contain;
    background-position: center, center;
  }

  @include phones {
    min-height: 500px;
    padding: $gap1 $gap2 0;
  }

  &__wrap {
    max-width: 1362px;
    padding: 0 $gap2;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablets {
      width: 100%;
      margin: 0;
    }
  }

  &__title {
    position: relative;
    text-align: center;

    @include tablets {
      max-width: 680px;
    }

    @include phones-landscape {
      max-width: 500px;
    }

    @include phones {
      max-width: 300px;
    }

    h3 {
      margin-bottom: $gap2;

      @include phones {
        margin-bottom: 14px;
      }
    }
  }

  &__description {
    max-width: 535px;
    text-align: center;
    margin-top: $gap4;

    @include phones-landscape {
      max-width: 100%;
      margin-top: 24px;
    }
  }
}

// about-us
.sky {
  position: absolute;
  top: -56px;
  right: 17%;

  @include phones {
    display: none;
  }
}

.about {
  margin: $gap4 $gap4 80px;
  position: relative;

  @include tablets {
    margin: $gap2 $gap2 55px;
  }

  @include phones {
    margin: 0 0 55px;
  }

  &__content {
    background: $white;
    border-radius: 40px;
    padding: 155px 112px;
    display: flex;
    justify-content: space-between;
    background-image: url("../img/skies.svg");
    background-repeat: no-repeat;
    background-size: 85%;
    background-position: 195px -31px;
    max-width: 1362px;
    margin: 0 auto;

    @include tablets {
      flex-direction: column;
      max-width: 100%;
      padding: $gap4 $gap2;
      background-image: none;
      border-radius: 30px;
    }
  }

  &__title {
    max-width: 600px;

    @include tablets {
      max-width: 100%;
    }

    h2 {
      @include font(600, 60px, 78px, $regular-font);
      margin: $gap3 0;
      padding-right: $gap2;

      @include tablets {
        margin: 16px 0 24px;
        padding-right: 0;
      }

      @include phones-landscape {
        @include font(600, 30px, 41px);
      }
    }
  }

  &__description {
    p {
      max-width: 430px;
      margin-bottom: $gap4;

      &:last-child {
        margin-bottom: 0;
      }
      @include tablets {
        max-width: 100%;
      }
    }
  }
}

// industries
.industries {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  text-align: center;
  background: $white;
  padding: 154px 0 0;
  position: relative;

  @include phones-landscape {
    padding: $gap3 $gap2;
  }

  &__content {
    max-width: 1206px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2,
    h4 {
      max-width: 714px;
    }

    h2 {
      margin: $gap3 0 $gap4;
    }

    @include phones-landscape {
      h2 {
        margin: 16px 0 24px;
        max-width: 100%;
      }
    }
  }
}

/* modal  */
.modal {
  @include transition(all, 1s, linear);
  display: none;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 270px;
  width: 100%;
  height: 580px;
  overflow: hidden;

  &__content {
    @include transition();
    background: $background-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-height: 100vh;
    max-width: 100vw;
    margin: auto;
    padding: $gap2;
    text-align: left;
    cursor: default;

    .close {
      @include transition();
      color: $close-btn;
      display: flex;
      justify-content: flex-end;
      font-size: 24px;

      &:hover,
      &:focus {
        @include transition();
        color: $link-hover-color;
        cursor: pointer;
      }
    }

    img {
      width: 220px;
      margin: 0 auto;
    }

    h2 {
      @include font(600, 18px, 21px, $regular-font);
      margin: $gap2 0 24px;
    }
  }
}

.modalBtn {
  @include font(700, 14px, 21px, $regular-font);
  color: $background-btn;
  cursor: pointer;
}

// slider
.slider {
  padding: 70px 0 $gap2 !important;
  max-width: 1323px;
  margin: 0 $gap2;

  @include tablets {
    max-width: 100% !important;
  }

  @include phones-landscape {
    padding: $gap3 0 $gap2 !important;
    padding: 0;
    margin: 0 !important;
  }

  &__container {
    background: $white;
  }

  &__wrap {
    width: auto;
    justify-content: center;
    display: flex;
    position: relative;
    height: 750px;

    @include phones-landscape {
      height: 550px;
      margin-left: 8px;
    }
  }

  &__content {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    background: $background-color;
    backdrop-filter: blur(42px);
    border-radius: 40px;
    outline: none;
    max-width: 1204px !important;
    width: 100%;
    height: 576px !important;
    margin-right: 48px;
    padding: 115px;

    @include tablets {
      flex-direction: column-reverse;
      align-items: left;
      border-radius: 30px;
      max-width: 100% !important;
      width: auto;
      height: 620px !important;
      margin-right: 18px;
      padding: $gap4;
    }

    @include phones-landscape {
      height: 450px !important;
      text-align: left;
      margin-right: 8px;
      padding: $gap3 $gap2;
      width: auto;
    }

    @include phones {
      width: 270px;
      padding: $gap2;
    }

    &-wrap {
      max-width: 420px;
      @include tablets {
        max-width: 600px;
      }

      @include phones {
        max-width: 100%;
      }
    }

    h2 {
      @include font(600, 32px, 41px, $regular-font);
      margin: 0 0 $gap3;

      @include tablets {
        @include font(600, 18px, 24px);
        margin: 16px 0 24px;
        max-width: 100%;
      }
      @include phones-landscape {
        margin: 16px 0;
      }
    }

    p {
      width: 100%;
    }

    &-img {
      img {
        @include tablets {
          margin: 0 auto;
          width: 70%;
        }
        @include phones-landscape {
          width: 50%;
        }

        @include phones {
          width: 180px;
        }
      }
    }
  }

  .text {
    @include phones {
      display: none;
    }
  }

  .text-mob {
    display: none;

    @include phones {
      display: block;
    }

    span {
      @include phones {
        display: none;
      }
    }
  }
}

// expertise
.expertise {
  padding: 140px 0 200px;

  @include phones-landscape {
    padding: $gap4 0 70px;
  }

  &__content {
    max-width: 1204px;
    margin: 0 auto;
    padding: 45px $gap2 0;

    @include tablets {
      max-width: 100%;
    }

    @include phones-landscape {
      margin: 0;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 55px;

    @include tablets {
      flex-direction: column-reverse;
      margin-bottom: 0px;
    }

    &-title {
      max-width: 750px;

      @include tablets {
        max-width: 100%;
      }

      h2 {
        color: $primary-text;
        text-transform: none;
        padding: $gap2 0 $gap4;
        max-width: 680px;

        @include tablets {
          max-width: 100%;
          padding: 7px 0 16px;
        }
      }

      p {
        max-width: 574px;

        @include tablets {
          max-width: 100%;
        }
      }
    }

    &-img {
      @include phones-landscape {
        display: flex;
        justify-content: center;
      }

      img {
        width: 587px;
        height: 422px;

        @include tablets {
          margin-bottom: 35px;
        }

        @include phones {
          width: 290px;
          height: auto;
        }
      }
    }
  }

  &__block {
    margin-top: 65px;

    @include phones-landscape {
      margin-top: 48px;
    }

    &-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 60px;

      @include phones-landscape {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &-img {
      display: flex;
      justify-content: center;
      width: 100px;
      height: 100px;
      background: $background-color;
      border-radius: 20px;
      margin-bottom: 16px;

      @include phones-landscape {
        border-radius: 10px;
        width: 64px;
        height: 64px;
      }
    }

    &-img-wrap {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        @include phones-landscape {
          width: 65%;
        }
      }
    }

    p {
      max-width: 336px;
      padding-top: 24px;

      @include tablets {
        max-width: 100%;
      }

      @include phones-landscape {
        padding-top: 16px;
      }
    }
  }
}

// contact-footer
.contact {
  width: 1206px;
  margin: 0 auto;

  @include tablets {
    width: auto;
    margin: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    background: $background-color;
    backdrop-filter: blur(42px);
    border-radius: 40px;
    padding: 90px;

    @include tablets {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      border-radius: 30px;
      padding: $gap4;
      margin: 0 $gap2;
    }

    @include phones {
      border-radius: 0;
      margin: 0;
      padding: 50px $gap2;
    }

    &-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;

      @include tablets {
        align-items: center;
      }

      & > h2 {
        margin: 7px 0 $gap3;
      }

      & > a {
        @include font(700, 20px, 20px, $regular-font);
        @include transition(all, 0.25s, linear);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 176px;
        background: $background-btn;
        color: $white;
        height: 64px;
        border-radius: 50px;
        cursor: pointer;

        @include phones-landscape {
          width: 100%;
        }

        &:hover {
          @include transition(all, 0.25s, linear);
          background: $background-btn-hover;
        }
      }
    }
  }

  &__img {
    img {
      width: 400px;

      @include tablets {
        margin-bottom: 50px;
      }

      @include phones {
        width: 268px;
      }
    }
  }

  &__information {
    @include font(300, 14px, 21px, $regular-font);
    display: flex;
    margin: 35px $gap2;

    @include tablets {
      flex-direction: column;
      text-align: center;
      margin: $gap2;
    }

    p {
      margin-right: 25px;

      @include tablets {
        margin: 0;
      }
    }
  }
}
